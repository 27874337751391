import React from "react";
import { Link } from "react-router-dom";

function GameBox() {
  return (
    <div>
      <div className="CG-Main_Box">
        <div className="Games_Box">   
          
          <div className="Image_Box">
            <Link to="/snowlandadventure" className="Image_Box_Link">
              <img src={require("../../Assets/96.png")} alt="Games" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GameBox;
