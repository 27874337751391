import React from "react";
import { Link } from "react-router-dom";

function GameBox() {
  return (
    <div>
      <div className="CG-Main_Box">
        <div className="Games_Box">   
          {/* <div className="Image_Box">
            <Link to="/GuessWho" className="Image_Box_Link">
              <img src="../Assets/11.png" alt="Games" />
            </Link>
            <article hidden>GuessWho</article>
          </div>

          <div className="Image_Box">
            <Link to="/BladeBallon" className="Image_Box_Link">
              <img src="../Assets/12.png" alt="Games" />
            </Link>
            <article hidden>BladeBallon</article>
          </div>

          <div className="Image_Box">
            <Link to="/Dual" className="Image_Box_Link">
              <img src="../Assets/13.png" alt="Games" />
            </Link>
            <article hidden>Dual</article>
          </div>

          <div className="Image_Box">
            <Link to="/DeoNether" className="Image_Box_Link">
              <img src="../Assets/14.png" alt="Games" />
            </Link>
            <article hidden>DeoNether</article>
          </div>

          <div className="Image_Box">
            <Link to="/SlotBallFes" className="Image_Box_Link">
              <img src="../Assets/15.png" alt="Games" />
            </Link>
            <article hidden>SlotBallFes</article>
          </div>

          <div className="Image_Box">
            <Link to="/HappySnake2" className="Image_Box_Link">
              <img src="../Assets/16.png" alt="Games" />
            </Link>
            <article hidden>HappySnake2</article>
          </div>

          <div className="Image_Box">
            <Link to="/BallCar" className="Image_Box_Link">
              <img src="../Assets/17.png" alt="Games" />
            </Link>
            <article hidden>BallCar</article>
          </div>

          <div className="Image_Box">
            <Link to="/BoyPingPong" className="Image_Box_Link">
              <img src="../Assets/18.png" alt="Games" />
            </Link>
            <article hidden>BoyPingPong</article>
          </div>

          <div className="Image_Box">
            <Link to="/PushPuzzleFootball" className="Image_Box_Link">
              <img src="../Assets/19.png" alt="Games" />
            </Link>
            <article hidden>PushPuzzleFootball</article>
          </div>

          <div className="Image_Box">
            <Link to="/FindGold" className="Image_Box_Link">
              <img src="../Assets/20.png" alt="Games" />
            </Link>
            <article hidden>FindGold</article>
          </div>

          <div className="Image_Box">
            <Link to="/SuperBitcoin" className="Image_Box_Link">
              <img src="../Assets/21.png" alt="Games" />
            </Link>
            <article hidden>SuperBitcoin</article>
          </div>

          <div className="Image_Box">
            <Link to="/SlotMachineFruits" className="Image_Box_Link">
              <img src="../Assets/22.png" alt="Games" />
            </Link>
            <article hidden>SlotMachineFruits</article>
          </div>

          <div className="Image_Box">
            <Link to="/GapGap" className="Image_Box_Link">
              <img src="../Assets/23.png" alt="Games" />
            </Link>
            <article hidden>GapGap</article>
          </div>

          <div className="Image_Box">
            <Link to="/Around" className="Image_Box_Link">
              <img src="../Assets/24.png" alt="Games" />
            </Link>
            <article hidden>Around</article>
          </div>

          <div className="Image_Box">
            <Link to="/RailMaze" className="Image_Box_Link">
              <img src="../Assets/25.png" alt="Games" />
            </Link>
            <article hidden>RailMaze</article>
          </div>

          <div className="Image_Box">
            <Link to="/ColorGrid" className="Image_Box_Link">
              <img src="../Assets/26.png" alt="Games" />
            </Link>
            <article hidden>ColorGrid</article>
          </div>

          <div className="Image_Box">
            <Link to="/MergeCandy" className="Image_Box_Link">
              <img src="../Assets/27.png" alt="Games" />
            </Link>
            <article hidden>MergeCandy</article>
          </div>

          <div className="Image_Box">
            <Link to="/RiverCrossing" className="Image_Box_Link">
              <img src="../Assets/28.png" alt="Games" />
            </Link>
            <article hidden>RiverCrossing</article>
          </div>

          <div className="Image_Box">
            <Link to="/CandyDash" className="Image_Box_Link">
              <img src="../Assets/29.png" alt="Games" />
            </Link>
            <article hidden>CandyDash</article>
          </div>

          <div className="Image_Box">
            <Link to="/CollectAnimals" className="Image_Box_Link">
              <img src="../Assets/30.png" alt="Games" />
            </Link>
            <article hidden>CollectAnimals</article>
          </div>

          <div className="Image_Box">
            <Link to="/SlotCowboys" className="Image_Box_Link">
              <img src="../Assets/31.png" alt="Games" />
            </Link>
            <article hidden>SlotCowboys</article>
          </div>

          <div className="Image_Box">
            <Link to="/BridgeRunner" className="Image_Box_Link">
              <img src="../Assets/32.png" alt="Games" />
            </Link>
            <article hidden>BridgeRunner</article>
          </div>

          <div className="Image_Box">
            <Link to="/BoxTower" className="Image_Box_Link">
              <img src="../Assets/33.png" alt="Games" />
            </Link>
            <article hidden>BoxTower</article>
          </div>

          <div className="Image_Box">
            <Link to="/ExtremePlinko" className="Image_Box_Link">
              <img src="../Assets/34.png" alt="Games" />
            </Link>
            <article hidden>ExtremePlinko</article>
          </div>

          <div className="Image_Box">
            <Link to="/IcyInterval" className="Image_Box_Link">
              <img src="../Assets/35.png" alt="Games" />
            </Link>
            <article hidden>IcyInterval</article>
          </div>

          <div className="Image_Box">
            <Link to="/CreepyFlappy" className="Image_Box_Link">
              <img src="../Assets/36.png" alt="Games" />
            </Link>
            <article hidden>CreepyFlappy</article>
          </div>

          <div className="Image_Box">
            <Link to="/DogeBlocks" className="Image_Box_Link">
              <img src="../Assets/37.png" alt="Games" />
            </Link>
            <article hidden>DogeBlocks</article>
          </div>

          <div className="Image_Box">
            <Link to="/MonthsChallenge" className="Image_Box_Link">
              <img src="../Assets/38.png" alt="Games" />
            </Link>
            <article hidden>MonthsChallenge</article>
          </div>

          <div className="Image_Box">
            <Link to="/PlayDominoes" className="Image_Box_Link">
              <img src="../Assets/39.png" alt="Games" />
            </Link>
            <article hidden>PlayDominoes</article>
          </div>

          <div className="Image_Box">
            <Link to="/SingleChain" className="Image_Box_Link">
              <img src="../Assets/40.png" alt="Games" />
            </Link>
            <article hidden>SingleChain</article>
          </div>

          <div className="Image_Box">
            <Link to="/ClickerCuteAnimals" className="Image_Box_Link">
              <img src="../Assets/41.png" alt="Games" />
            </Link>
            <article hidden>ClickerCuteAnimals</article>
          </div>

          <div className="Image_Box">
            <Link to="/NinjaFruitSlice" className="Image_Box_Link">
              <img src="../Assets/42.png" alt="Games" />
            </Link>
            <article hidden>NinjaFruitSlice</article>
          </div>

          <div className="Image_Box">
            <Link to="/Basket" className="Image_Box_Link">
              <img src="../Assets/43.png" alt="Games" />
            </Link>
            <article hidden>Basket</article>
          </div>

          <div className="Image_Box">
            <Link to="/FinishTheDrawing" className="Image_Box_Link">
              <img src="../Assets/44.png" alt="Games" />
            </Link>
            <article hidden>FinishTheDrawing</article>
          </div>

          <div className="Image_Box">
            <Link to="/ShadowForestSlot" className="Image_Box_Link">
              <img src="../Assets/45.png" alt="Games" />
            </Link>
            <article hidden>ShadowForestSlot</article>
          </div>

          <div className="Image_Box">
            <Link to="/IconSpinWheels" className="Image_Box_Link">
              <img src="../Assets/46.png" alt="Games" />
            </Link>
            <article hidden>IconSpinWheels</article>
          </div>

          <div className="Image_Box">
            <Link to="/SlotAstro" className="Image_Box_Link">
              <img src="../Assets/47.png" alt="Games" />
            </Link>
            <article hidden>SlotAstro</article>
          </div>

          <div className="Image_Box">
            <Link to="/Moles" className="Image_Box_Link">
              <img src="../Assets/48.png" alt="Games" />
            </Link>
            <article hidden>Moles</article>
          </div>

          <div className="Image_Box">
            <Link to="/MayaRuins" className="Image_Box_Link">
              <img src="../Assets/49.png" alt="Games" />
            </Link>
            <article hidden>MayaRuins</article>
          </div>

          <div className="Image_Box">
            <Link to="/AirHockey" className="Image_Box_Link">
              <img src="../Assets/50.png" alt="Games" />
            </Link>
            <article hidden>AirHockey</article>
          </div>

          <div className="Image_Box">
            <Link to="/FruitsFit" className="Image_Box_Link">
              <img src="../Assets/51.png" alt="Games" />
            </Link>
            <article hidden>FruitsFit</article>
          </div>

          <div className="Image_Box">
            <Link to="/TreasureJungle" className="Image_Box_Link">
              <img src="../Assets/52.png" alt="Games" />
            </Link>
            <article hidden>TreasureJungle</article>
          </div>

          <div className="Image_Box">
            <Link to="/SortBucket" className="Image_Box_Link">
              <img src="../Assets/53.png" alt="Games" />
            </Link>
            <article hidden>SortBucket</article>
          </div>

          <div className="Image_Box">
            <Link to="/DotBlast" className="Image_Box_Link">
              <img src="../Assets/54.png" alt="Games" />
            </Link>
            <article hidden>DotBlast</article>
          </div>

          <div className="Image_Box">
            <Link to="/NoelNavigate" className="Image_Box_Link">
              <img src="../Assets/55.png" alt="Games" />
            </Link>
            <article hidden>NoelNavigate</article>
          </div>

          <div className="Image_Box">
            <Link to="/Colors" className="Image_Box_Link">
              <img src="../Assets/56.png" alt="Games" />
            </Link>
            <article hiddColorsen></article>
          </div>

          <div className="Image_Box">
            <Link to="/FindMyDog" className="Image_Box_Link">
              <img src="../Assets/57.png" alt="Games" />
            </Link>
            <article hidden>FindMyDog</article>
          </div>

          <div className="Image_Box">
            <Link to="/SportCrush" className="Image_Box_Link">
              <img src="../Assets/58.png" alt="Games" />
            </Link>
            <article hidden>SportCrush</article>
          </div>

          <div className="Image_Box">
            <Link to="/ApplesCut" className="Image_Box_Link">
              <img src="../Assets/59.png" alt="Games" />
            </Link>
            <article hidden>ApplesCut</article>
          </div>

          <div className="Image_Box">
            <Link to="/Numberle" className="Image_Box_Link">
              <img src="../Assets/60.png" alt="Games" />
            </Link>
            <article hidden>Numberle</article>
          </div>

          <div className="Image_Box">
            <Link to="/BrainGame" className="Image_Box_Link">
              <img src="../Assets/61.png" alt="Games" />
            </Link>
            <article hidden>BrainGame</article>
          </div>

          <div className="Image_Box">
            <Link to="/Spaceship" className="Image_Box_Link">
              <img src="../Assets/62.png" alt="Games" />
            </Link>
            <article hidden>Spaceship</article>
          </div>

          <div className="Image_Box">
            <Link to="/Stoneman" className="Image_Box_Link">
              <img src="../Assets/63.png" alt="Games" />
            </Link>
            <article hidden>Stoneman</article>
          </div>

          <div className="Image_Box">
            <Link to="/Tricolor" className="Image_Box_Link">
              <img src="../Assets/64.png" alt="Games" />
            </Link>
            <article hidden>Tricolor</article>
          </div>

          <div className="Image_Box">
            <Link to="/TopJump" className="Image_Box_Link">
              <img src="../Assets/65.png" alt="Games" />
            </Link>
            <article hiddTopJumpen></article>
          </div>

          <div className="Image_Box">
            <Link to="/DesertBuilding" className="Image_Box_Link">
              <img src="../Assets/66.png" alt="Games" />
            </Link>
            <article hidden>DesertBuilding</article>
          </div>

          <div className="Image_Box">
            <Link to="/CrazyCannons" className="Image_Box_Link">
              <img src="../Assets/67.png" alt="Games" />
            </Link>
            <article hidden>CrazyCannons</article>
          </div>

          <div className="Image_Box">
            <Link to="/ChooseYourOdds" className="Image_Box_Link">
              <img src="../Assets/68.png" alt="Games" />
            </Link>
            <article hidden>ChooseYourOdds</article>
          </div>

          <div className="Image_Box">
            <Link to="/TheMagicOfScience" className="Image_Box_Link">
              <img src="../Assets/69.png" alt="Games" />
            </Link>
            <article hidden>TheMagicOfScience</article>
          </div>

          <div className="Image_Box">
            <Link to="/Fishes" className="Image_Box_Link">
              <img src="../Assets/70.png" alt="Games" />
            </Link>
            <article hiddFishesen></article>
          </div> */}

          <div className="Image_Box">
            <Link to="/BabySupermarts" className="Image_Box_Link">
              <img src={require("../Assets/71.png")} alt="Games" />
            </Link>
            <article hidden>BabySupermarts</article>
          </div>

          <div className="Image_Box">
            <Link to="/ArcherBattle" className="Image_Box_Link">
              <img src={require("../Assets/72.png")} alt="Games" />
            </Link>
            <article hidden>ArcherBattle</article>
          </div>

          <div className="Image_Box">
            <Link to="/Arrows" className="Image_Box_Link">
              <img src={require("../Assets/73.png")} alt="Games" />
            </Link>
            <article hiddArrowsen></article>
          </div>

          <div className="Image_Box">
            <Link to="/DogPuzzle" className="Image_Box_Link">
              <img src={require("../Assets/74.png")} alt="Games" />
            </Link>
            <article hidden>DogPuzzle</article>
          </div>

          <div className="Image_Box">
            <Link to="/WordQuest" className="Image_Box_Link">
              <img src={require("../Assets/75.png")} alt="Games" />
            </Link>
            <article hidden>WordQuest</article>
          </div>

          <div className="Image_Box">
            <Link to="/RocketDefender" className="Image_Box_Link">
              <img src={require("../Assets/76.png")} alt="Games" />
            </Link>
            <article hidden>RocketDefender</article>
          </div>

          <div className="Image_Box">
            <Link to="/SpeedNeon" className="Image_Box_Link">
              <img src={require("../Assets/77.png")} alt="Games" />
            </Link>
            <article hidden>SpeedNeon</article>
          </div>

          <div className="Image_Box">
            <Link to="/FourColors" className="Image_Box_Link">
              <img src={require("../Assets/78.png")} alt="Games" />
            </Link>
            <article hidden>FourColors</article>
          </div>

          <div className="Image_Box">
            <Link to="/GuessTheFlag" className="Image_Box_Link">
              <img src={require("../Assets/79.png")} alt="Games" />
            </Link>
            <article hidden>GuessTheFlag</article>
          </div>

          <div className="Image_Box">
            <Link to="/TiltPainting" className="Image_Box_Link">
              <img src={require("../Assets/80.png")} alt="Games" />
            </Link>
            <article hidden>TiltPainting</article>
          </div>

          <div className="Image_Box">
            <Link to="/WordConnect" className="Image_Box_Link">
              <img src={require("../Assets/81.png")} alt="Games" />
            </Link>
            <article hidden>WordConnect</article>
          </div>

          <div className="Image_Box">
            <Link to="/CircuitGates" className="Image_Box_Link">
              <img src={require("../Assets/82.png")} alt="Games" />
            </Link>
            <article hidden>CircuitGates</article>
          </div>

          <div className="Image_Box">
            <Link to="/HatHands" className="Image_Box_Link">
              <img src={require("../Assets/83.png")} alt="Games" />
            </Link>
            <article hidden>HatHands</article>
          </div>

          <div className="Image_Box">
            <Link to="/Chidimaar" className="Image_Box_Link">
              <img src={require("../Assets/84.png")} alt="Games" />
            </Link>
            <article hidden>Chidimaar</article>
          </div>

          <div className="Image_Box">
            <Link to="/Pin" className="Image_Box_Link">
              <img src={require("../Assets/85.png")} alt="Games" />
            </Link>
            <articlPine hidden></articlPine>
          </div>

          <div className="Image_Box">
            <Link to="/ZombieNightmare" className="Image_Box_Link">
              <img src={require("../Assets/86.png")} alt="Games" />
            </Link>
            <article hidden>ZombieNightmare</article>
          </div>

          <div className="Image_Box">
            <Link to="/SkaterBoy" className="Image_Box_Link">
              <img src={require("../Assets/87.png")} alt="Games" />
            </Link>
            <article hidden>SkaterBoy</article>
          </div>

          <div className="Image_Box">
            <Link to="/RollBall" className="Image_Box_Link">
              <img src={require("../Assets/88.png")} alt="Games" />
            </Link>
            <article hidden>RollBall</article>
          </div>

          <div className="Image_Box">
            <Link to="/SpaceJet" className="Image_Box_Link">
              <img src={require("../Assets/89.png")} alt="Games" />
            </Link>
            <article hidden>SpaceJet</article>
          </div>

          <div className="Image_Box">
            <Link to="/BasketMode" className="Image_Box_Link">
              <img src={require("../Assets/90.png")} alt="Games" />
            </Link>
            <article hidden>BasketMode</article>
          </div>

          <div className="Image_Box">
            <Link to="/NinjaTime" className="Image_Box_Link">
              <img src={require("../Assets/91.png")} alt="Games" />
            </Link>
            <article hidden>NinjaTime</article>
          </div>

          <div className="Image_Box">
            <Link to="/Collide2" className="Image_Box_Link">
              <img src={require("../Assets/92.png")} alt="Games" />
            </Link>
            <article hidden>Collide2</article>
          </div>

          <div className="Image_Box">
            <Link to="/EggFarm" className="Image_Box_Link">
              <img src={require("../Assets/93.png")} alt="Games" />
            </Link>
            <article hiddEggFarmen></article>
          </div>

          <div className="Image_Box">
            <Link to="/TowerLevels" className="Image_Box_Link">
              <img src={require("../Assets/94.png")} alt="Games" />
            </Link>
            <article hidden>TowerLevels</article>
          </div>

          <div className="Image_Box">
            <Link to="/DualContrl" className="Image_Box_Link">
              <img src={require("../Assets/95.png")} alt="Games" />
            </Link>
            <article hidden>DualContrl</article>
          </div>

          <div className="Image_Box">
            <Link to="/SnowlandAdventure" className="Image_Box_Link">
              <img src={require("../Assets/96.png")} alt="Games" />
            </Link>
            <article hidden>SnowlandAdventure</article>
          </div>

          <div className="Image_Box">
            <Link to="/WatermelonDrop" className="Image_Box_Link">
              <img src={require("../Assets/97.png")} alt="Games" />
            </Link>
            <article hidden>WatermelonDrop</article>
          </div>

          <div className="Image_Box">
            <Link to="/RiverCrossing" className="Image_Box_Link">
              <img src={require("../Assets/98.png")} alt="Games" />
            </Link>
            <article hidden>RiverCrossing</article>
          </div>

          <div className="Image_Box">
            <Link to="/PatternMaster" className="Image_Box_Link">
              <img src={require("../Assets/99.png")} alt="Games" />
            </Link>
            <article hidden>PatternMaster</article>
          </div>

          <div className="Image_Box">
            <Link to="/AGoodTeacher" className="Image_Box_Link">
              <img src={require("../Assets/100.png")} alt="Games" />
            </Link>
            <article hidden>AGoodTeacher</article>
          </div>

          <div className="Image_Box">
            <Link to="/WobblyBoxing" className="Image_Box_Link">
              <img src="../Assets/101.png" alt="Games" />
            </Link>
            <article hidden>WobblyBoxing</article>
          </div>

          <div className="Image_Box">
            <Link to="/MathGeniusGame" className="Image_Box_Link">
              <img src="../Assets/102.png" alt="Games" />
            </Link>
            <article hidden>MathGeniusGame</article>
          </div>

          <div className="Image_Box">
            <Link to="/NumbersCloud" className="Image_Box_Link">
              <img src="../Assets/103.png" alt="Games" />
            </Link>
            <article hidden>NumbersCloud</article>
          </div>

          <div className="Image_Box">
            <Link to="/WatermelonFruit" className="Image_Box_Link">
              <img src="../Assets/104.png" alt="Games" />
            </Link>
            <article hidden>WatermelonFruit</article>
          </div>

          <div className="Image_Box">
            <Link to="/Plinko" className="Image_Box_Link">
              <img src="../Assets/105.png" alt="Games" />
            </Link>
            <article hiddPlinkoen></article>
          </div>

          <div className="Image_Box">
            <Link to="/PizzaGuyPuzzle" className="Image_Box_Link">
              <img src="../Assets/106.png" alt="Games" />
            </Link>
            <article hidden>PizzaGuyPuzzle</article>
          </div>

          <div className="Image_Box">
            <Link to="/ArcoBall" className="Image_Box_Link">
              <img src="../Assets/107.png" alt="Games" />
            </Link>
            <article hiddArcoBallen></article>
          </div>

          <div className="Image_Box">
            <Link to="/SlotKites" className="Image_Box_Link">
              <img src="../Assets/108.png" alt="Games" />
            </Link>
            <article hidden>SlotKites</article>
          </div>

          <div className="Image_Box">
            <Link to="/FindMiniMe" className="Image_Box_Link">
              <img src="../Assets/109.png" alt="Games" />
            </Link>
            <article hidden>FindMiniMe</article>
          </div>

          <div className="Image_Box">
            <Link to="/RogueRaider" className="Image_Box_Link">
              <img src="../Assets/110.png" alt="Games" />
            </Link>
            <article hidden>RogueRaider</article>
          </div>

          <div className="Image_Box">
            <Link to="/MonsterEscape" className="Image_Box_Link">
              <img src="../Assets/111.png" alt="Games" />
            </Link>
            <article hidden>MonsterEscape</article>
          </div>

          <div className="Image_Box">
            <Link to="/CarRush" className="Image_Box_Link">
              <img src="../Assets/112.png" alt="Games" />
            </Link>
            <article hiddCarRushen></article>
          </div>

          <div className="Image_Box">
            <Link to="/StackCheese" className="Image_Box_Link">
              <img src="../Assets/113.png" alt="Games" />
            </Link>
            <article hidden>StackCheese</article>
          </div>

          <div className="Image_Box">
            <Link to="/PastryChefBubble" className="Image_Box_Link">
              <img src="../Assets/114.png" alt="Games" />
            </Link>
            <article hidden>PastryChefBubble</article>
          </div>

          <div className="Image_Box">
            <Link to="/AngryShooter" className="Image_Box_Link">
              <img src="../Assets/115.png" alt="Games" />
            </Link>
            <article hidden>AngryShooter</article>
          </div>

          <div className="Image_Box">
            <Link to="/FormulaRush" className="Image_Box_Link">
              <img src="../Assets/116.png" alt="Games" />
            </Link>
            <article hidden>FormulaRush</article>
          </div>

          <div className="Image_Box">
            <Link to="/Plux" className="Image_Box_Link">
              <img src="../Assets/117.png" alt="Games" />
            </Link>
            <articlPluxe hidden></articlPluxe>
          </div>

          <div className="Image_Box">
            <Link to="/FruitMergeReoader" className="Image_Box_Link">
              <img src="../Assets/118.png" alt="Games" />
            </Link>
            <article hidden>FruitMergeReoader</article>
          </div>

          <div className="Image_Box Secgame_Box">
            <Link to="/CircuitGates" className="Image_Box_Link">
              <img src="../Assets/119.png" alt="Games" />
            </Link>
            <article hidden>CircuitGates</article>
          </div>

          <div className="Image_Box Secgame_Box">
            <Link to="/SeekAndFind" className="Image_Box_Link">
              <img src="../Assets/120.png" alt="Games" />
            </Link>
            <article hidden>SeekAndFind</article>
          </div>

        </div>
      </div>
    </div>
  );
}

export default GameBox;


// import React, { useState } from "react";
// import data from "../Json/Games.json";
// import { Link } from "react-router-dom";

// function GameBox() {
//   const [searchTerm, setSearchTerm] = useState("");
//   return (
//     <>
//       <div className="templateContainer">
//         <div className="searchInput_Container">
//           <input id="searchInput" type="text" placeholder="Search here..." onChange={(event) => {
//             setSearchTerm(event.target.value);
//           }} />
//         </div>
//         <div className="CG-Main_Box">
//           <div className="Games_Box">
//             {
//               data.filter((val) => {
//                 if (searchTerm === "") {
//                   return val;
//                 } else if (val.Name.toLowerCase().includes(searchTerm.toLowerCase())) {
//                   return val;
//                 }
//               })
//                 .map((val) => {
//                   return (
//                     <div className="Image_Box Secgame_Box">
//                       <Link to={val.Url} className="Image_Box_Link">
//                         <img src={val.img} alt="Games" />
//                       </Link>
//                       <article hidden>{val.Name}</article>
//                     </div>
//                   )
//                 })
//             }
//           </div>
//         </div>
//       </div>
//     </>
//   )
// }

// export default GameBox;