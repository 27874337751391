import React from "react";
import { Link } from "react-router-dom";

function Blog() {
  return (
    <div>
      <div className="Main-Blog">
        <div className="MB-Section">
          <div className="Main_Blog_Box">
            <Link to="/BabySupermarts" className="Sec_Main_Blog">
              <div className="b71">
                <h1>Baby Supermarts</h1>
                {/* <article>
                    Guess The All Character And Choose Your Character And Enjoy Game 
                </article> */}
              </div>
            </Link>

            <Link to="/ArcherBattle" className="Sec_Main_Blog">
              <div className="b72">
                <h1>Archer Battle</h1>
                {/* <article>
                    Touch The Same Color Ballon And Blast It For Store More Ballon
                </article> */}
              </div>
            </Link>

            <Link to="/Arrows" className="Sec_Main_Blog">
              <div className="b73">
                <h1>Arrows</h1>
                {/* <article>You Have Two Balls And Save From Upcoming Wars</article> */}
              </div>
            </Link>

            <Link to="/DogPuzzle" className="Sec_Main_Blog">
              <div className="b74">
                <h1>Dog Puzzle</h1>
                {/* <article>This Game You Have Two Characters And You Have To Go In Finish Line</article> */}
              </div>
            </Link>

            <Link to="/WordQuest" className="Sec_Main_Blog">
              <div className="b75">
                <h1>Word Quest</h1>
                {/* <article>This Game You Have Chance To Win Drow</article> */}
              </div>
            </Link>

            <Link to="/RocketDefender" className="Sec_Main_Blog">
              <div className="b76">
                <h1>Rocket Defender</h1>
                {/* <article>This Snake Is Finding A Food And Eat At To Snake</article> */}
              </div>
            </Link>

            <Link to="/SpeedNeon" className="Sec_Main_Blog">
              <div className="b77">
                <h1>Speed Neon</h1>
                {/* <article>
                    Save Lemon In Car And Run Car In Finish Line And You Win The Game
                </article> */}
              </div>
            </Link>
            
            <Link to="/FourColors" className="Sec_Main_Blog">
              <div className="b78">
                <h1>Four Colors</h1>
                {/* <article>Boy Playing With Ping Pong Ball Your Are Started To Play With Boy</article> */}
              </div>
            </Link>

            <Link to="/GuessTheFlag" className="Sec_Main_Blog">
              <div className="b79">
                <h1>Guess TheFlag</h1>
                {/* <article>Play Fott Ball In Digital Word With Your Digitel Frainds</article> */}
              </div>
            </Link>

            <Link to="/TiltPainting" className="Sec_Main_Blog">
              <div className="b80">
                <h1>Tilt Painting</h1>
                {/* <article>Main The Diamonds In This Game With The Digitel Charectars</article> */}
              </div>
            </Link>

            <Link to="/WordConnect" className="Sec_Main_Blog">
              <div className="b81">
                <h1>Word Connect</h1>
                {/* <article>Main The Bitcoin With Digital Freinds</article> */}
              </div>
            </Link>

            <Link to="/HatHands" className="Sec_Main_Blog">
              <div className="b82">
                <h1>Hat Hands</h1>
                {/* <article>Drive The Car And Collect The Fruits With Digital Car</article> */}
              </div>
            </Link>

            <Link to="/Chidimaar" className="Sec_Main_Blog">
              <div className="b83">
                <h1>Chidimaar</h1>
                {/* <article>Gap Gap Game You Have To Jump In Next Line With The Small Gap With Space Key</article> */}
              </div>
            </Link>

            <Link to="/Pin" className="Sec_Main_Blog">
              <div className="b84">
                <h1>Pin</h1>
                {/* <article>You And Your Fraind Play With One Keybord With Arrow Key And "a" "d" Key</article> */}
              </div>
            </Link>

            <Link to="/ZombieNightmare" className="Sec_Main_Blog">
              <div className="b85">
                <h1>Zombie Nightmare</h1>
                {/* <article>Collect The Old And New Type Of Coin And Build Your Collection</article> */}
              </div>
            </Link>

            <Link to="/SkaterBoy" className="Sec_Main_Blog">
              <div className="b86">
                <h1>Skater Boy</h1>
                {/* <article>Create Your Own Color Full Drowing With This Tool Game</article> */}
              </div>
            </Link>

            <Link to="/RollBall" className="Sec_Main_Blog">
              <div className="b87">
                <h1>RollBall</h1>
                {/* <article>Merge Candy Collect The Candys In The Market Lorry And Sell It</article> */}
              </div>
            </Link>
            
            <Link to="/SpaceJet" className="Sec_Main_Blog">
              <div className="b88">
                <h1>SpaceJet</h1>
                {/* <article>River Crosing Crosing The River In The Digitel World Boy Charectar</article> */}
              </div>
            </Link>

            <Link to="/BasketMode" className="Sec_Main_Blog">
              <div className="b89">
                <h1>Basket Mode</h1>
                {/* <article>Candy Dash In This Game You Collect And Blast Candys</article> */}
              </div>
            </Link>

            <Link to="/CollectAnimals" className="Sec_Main_Blog">
              <div className="b90">
                <h1>Ninja Time</h1>
                {/* <article>Collect Animals In This Game You Have To Collect The Animals In Forest</article> */}
              </div>
            </Link>

            <Link to="/Collide2" className="Sec_Main_Blog">
              <div className="b91">
                <h1>Collide 2</h1>
                {/* <article>This Game You Have Chance To Win Drow</article> */}
              </div>
            </Link>

            <Link to="/EggFarm" className="Sec_Main_Blog">
              <div className="b92">
                <h1>Egg Farm</h1>
                {/* <article>You Have To Collect Your Color Blocks And Fix It In Row For Go Up Side</article> */}
              </div>
            </Link>

            <Link to="/TowerLevels" className="Sec_Main_Blog">
              <div className="b93">
                <h1>Tower Levels</h1>
                {/* <article>Play With One Player And Complate In Minimum Time And Win</article> */}
              </div>
            </Link>

            <Link to="/DualContrl" className="Sec_Main_Blog">
              <div className="b94">
                <h1>Dual Contrl</h1>
                {/* <article> Drop The All Ball And Earn Money With The Percentes Like Maximum 420%</article> */}
              </div>
            </Link>

            <Link to="/SnowlandAdventure" className="Sec_Main_Blog">
              <div className="b95">
                <h1>Snowland Adventure</h1>
                {/* <article>Icy Interval you In Icy Interface With One Car You Have To Remove All Icy Part</article> */}
              </div>
            </Link>

            <Link to="/WatermelonDrop" className="Sec_Main_Blog">
              <div className="b96">
                <h1>Watermelon Drop</h1>
                {/* <article>Creepy Flappy In This Game You Are In Danger Ara And Safe</article> */}
              </div>
            </Link>

            <Link to="/RiverCrossing" className="Sec_Main_Blog">
              <div className="b97">
                <h1>River Crossing</h1>
                {/* <article>Dog Block In This Game You Set Block On Block And Create A Safe Space For Dog</article> */}
              </div>
            </Link>
            
            <Link to="/PatternMaster" className="Sec_Main_Blog">
              <div className="b98">
                <h1>Pattern Master</h1>
                {/* <article>Months In This Game You Find The Month And Choose ame Of This Month</article> */}
              </div>
            </Link>

            <Link to="/AGoodTeacher" className="Sec_Main_Blog">
              <div className="b99">
                <h1>AGood Teacher</h1>
                {/* <article>Dominoes In This Game You Have A One Dominoes With And Swap It More Than More Time</article> */}
              </div>
            </Link>

            <Link to="/WobblyBoxing" className="Sec_Main_Blog">
              <div className="b100">
                <h1>Wobbly Boxing</h1>
                {/* <article>Jint Chain In This Game You Have To Join All The Elements And Create A Chain</article> */}
              </div>
            </Link>

            {/* <Link to="/MathGeniusGame" className="Sec_Main_Blog">
              <div className="G41">
                <h1>Math GeniusGame</h1>
                <article>For You Won This Game You Want To Safe From All The Animals</article>
              </div>
            </Link>

            <Link to="/NumbersCloud" className="Sec_Main_Blog">
              <div className="G42">
                <h1>Numbers Cloud</h1>
                <article>You Want To Slice It All Fruits For Wining The Game And Earn More.</article>
              </div>
            </Link>

            <Link to="/WatermelonFruit" className="Sec_Main_Blog">
              <div className="G43">
                <h1>Watermelon Fruit</h1>
                <article>For You Won This Game You Want To Safe From All The Animals</article>
              </div>
            </Link>

            <Link to="/Plinko" className="Sec_Main_Blog">
              <div className="G44">
                <h1>Plinko</h1>
                <article>Hello Player Play All Games And Fun With Other Players.</article>
              </div>
            </Link>

            <Link to="/PizzaGuyPuzzle" className="Sec_Main_Blog">
              <div className="G45">
                <h1>Pizza GuyPuzzle</h1>
                <article>Main The Diamonds In This Game With The Digitel Charectars</article>
              </div>
            </Link>

            <Link to="/ArcoBall" className="Sec_Main_Blog">
              <div className="G46">
                <h1>Arco Ball</h1>
                <article>Main The Diamonds In This Game With The Digitel Charectars</article>
              </div>
            </Link>

            <Link to="/SlotKites" className="Sec_Main_Blog">
              <div className="G47">
                <h1>Slot Kites</h1>
                <article>Hello Player Play All Games And Fun With Other Players.</article>
              </div>
            </Link>
            
            <Link to="/FindMiniMe" className="Sec_Main_Blog">
              <div className="G48">
                <h1>Find MiniMe</h1>
                <article>For You Won This Game You Want To Safe From All The Animals</article>
              </div>
            </Link>

            <Link to="/RogueRaider" className="Sec_Main_Blog">
              <div className="G49">
                <h1>Rogue Raider</h1>
                <article>Hello Player Play All Games And Fun With Other Players.</article>
              </div>
            </Link>

            <Link to="/AirHockey" className="Sec_Main_Blog">
              <div className="G50">
                <h1>Air Hockey</h1>
                <article>Hello Player Play All Games And Fun With Other Players.</article>
              </div>
            </Link>

            <Link to="/FruitsFit" className="Sec_Main_Blog">
              <div className="G51">
                <h1>Fruit Fit</h1>
                <article>You Want To Slice It All Fruits For Wining The Game And Earn More.</article>
              </div>
            </Link>

            <Link to="/TreasureJungle" className="Sec_Main_Blog">
              <div className="G52">
                <h1>Treasure</h1>
                <article>Hello Player Play All Games And Fun With Other Players.</article>
              </div>
            </Link>

            <Link to="/SortBucket" className="Sec_Main_Blog">
              <div className="G53">
                <h1>Sort Backet</h1>
                <article>Hello Player Play All Games And Fun With Other Players.</article>
              </div>
            </Link>

            <Link to="/DotBlast" className="Sec_Main_Blog">
              <div className="G54">
                <h1>Blast</h1>
                <article>Main The Diamonds In This Game With The Digitel Charectars</article>
              </div>
            </Link>

            <Link to="/NoelNavigate" className="Sec_Main_Blog">
              <div className="G55">
                <h1>Avigate</h1>
                <article>Hello Player Play All Games And Fun With Other Players.</article>
              </div>
            </Link>

            <Link to="/Colors" className="Sec_Main_Blog">
              <div className="G56">
                <h1>Colors</h1>
                <article>Hello Player Play All Games And Fun With Other Players.</article>
              </div>
            </Link>

            <Link to="/FindMyDog" className="Sec_Main_Blog">
              <div className="G57">
                <h1>Find Dog</h1>
                <article>Find Dog Game In This Game You Want To Find Out A Dog. </article>
              </div>
            </Link>
            
            <Link to="/SportCrush" className="Sec_Main_Blog">
              <div className="G58">
                <h1>Sport Crush</h1>
                <article>In This Sport Game You Are With The Sports Players With Games And All.</article>
              </div>
            </Link>

            <Link to="/ApplesCut" className="Sec_Main_Blog">
              <div className="G59">
                <h1>Apples</h1>
                <article>You Collect The All Apples And Sold It Out And Earn More</article>
              </div>
            </Link>

            <Link to="/Numberle" className="Sec_Main_Blog">
              <div className="G60">
                <h1>Numberle</h1>
                <article>Number Game You Have To Add Rendom Number And Sowl The Maths</article>
              </div>
            </Link>

            <Link to="/BrainGame" className="Sec_Main_Blog">
              <div className="G61">
                <h1>Brain Game</h1>
                <article>In This Brain Game You Use You Brain And Give Right Awnser</article>
              </div>
            </Link>

            <Link to="/Spaceship" className="Sec_Main_Blog">
              <div className="G62">
                <h1>Spaceship</h1>
                <article>This Moon Game You Have A Moon And Protect</article>
              </div>
            </Link>

            <Link to="/Stoneman" className="Sec_Main_Blog">
              <div className="G63">
                <h1>Crocodile</h1>
                <article>In This Game You Are On Building And Kill Animel And Protect Charactar</article>
              </div>
            </Link>

            <Link to="/Tricolor" className="Sec_Main_Blog">
              <div className="G64">
                <h1>Colors</h1>
                <article>Collect The All Colors And Create A One Bit Rouded Box With Multpul Colors</article>
              </div>
            </Link>

            <Link to="/TopJump" className="Sec_Main_Blog">
              <div className="G65">
                <h1>Colors -</h1>
                <article>Collect The All Colors And Create A One Bit Rouded Box With Multpul Colors</article>
              </div>
            </Link>

            <Link to="/DesertBuilding" className="Sec_Main_Blog">
              <div className="G66">
                <h1>Road Crose</h1>
                <article>In Road Game You Have A One Road With A Super Car And Drive The Car With Row Power</article>
              </div>
            </Link>

            <Link to="/CrazyCannons" className="Sec_Main_Blog">
              <div className="G67">
                <h1>Crazy Cannons</h1>
                <article>Crazy Cannon In Crazy Cannon You Have A Crazy Cannon And You Handal It Cannon</article>
              </div>
            </Link>
            
            <Link to="/ChooseYourOdds" className="Sec_Main_Blog">
              <div className="G68">
                <h1>Win Price</h1>
                <article>Win Price In This Game You Have To Win 100$ In One Time Tearms & Condistion Aplly</article>
              </div>
            </Link>

            <Link to="/TheMagicOfScience" className="Sec_Main_Blog">
              <div className="G69">
                <h1>2048</h1>
                <article>2048 In This Game You Have Some Boxes And You Have To Mix And Level Up</article>
              </div>
            </Link>

            <Link to="/Fishes" className="Sec_Main_Blog">
              <div className="G70">
                <h1>Fishing</h1>
                <article>Fishing In This Fishing Game You Have To Create A Fish With Blank Blocks</article>
              </div>
            </Link> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Blog;
