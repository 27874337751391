import React from "react";

function Description() {
  return (
    <div>
      <div className="Main_Descriptio">
        <div className="MD-Class">
          <div className="MD-Info">
            <p>
              Embark on an epic journey through the mythical realm of Aetheria,
              where legends are born and destinies are forged. In this immersive
              online RPG, you will uncover ancient mysteries, battle fearsome
              creatures, and build alliances with players from around the globe.
            </p>

            <h3>Key Features:</h3>

            <p>
              {" "}
              <b>1. Rich Storytelling</b> : Immerse yourself in a captivating
              narrative as you uncover the secrets of Aetheria's past and shape
              its future.
            </p>

            <p>
              {" "}
              <b>2. Dynamic Combat</b> : Engage in thrilling real-time battles
              with a blend of strategy and skill. Master unique abilities and
              unleash devastating combos.
            </p>

            <p>
              {" "}
              <b>3. Open World Exploration</b> : Explore vast landscapes filled
              with hidden treasures, challenging dungeons, and breathtaking
              vistas.
            </p>

            <p>
              {" "}
              <b>4. Player-driven Economy</b> : Forge alliances, trade
              resources, and build your empire in a world where every decision
              shapes the balance of power.
            </p>

            <p>
              {" "}
              <b>5. Social Interaction</b> : Join forces with friends and rivals
              alike in guilds and alliances. Compete in tournaments or
              collaborate on quests for epic rewards.
            </p>

            <p>
              {" "}
              <b>6. Customize Your Legend</b> : Personalize your character with
              a wide array of gear, weapons, and magical artifacts. Tailor your
              playstyle to become a legendary hero or a cunning rogue.
            </p>

            <p>
              {" "}
              <b>7. Regular Updates</b> : Experience new adventures with regular
              updates and seasonal events that keep the world of Aetheria alive
              and evolving.
            </p>

            <p>
              {" "}
              <b>8. Cross-platform Play</b> : Play seamlessly across multiple
              devices and platforms, ensuring you never miss a moment of
              adventure.
            </p>

            <p>
              Join the ranks of heroes and villains in Legends of Aetheria,
              where your choices define your legacy and the fate of a world
              hangs in the balance. Will you rise to become a legend?
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Description;
