import React from "react";
import HomeHeader from "../Components/Home/Header";
import GameBox from "../Components/GameBox";

const Stylesheets = {
  MarginUpDown: {
    marginTop: "17px",
    marginBottom: "17px",
  },
};

function Home() {
  return (
    <div>
      {/* <div className="HH-HomePage" style={Stylesheets.MarginUpDown}>
        <HomeHeader />
      </div> */}

      <div className="GB-HomePage" style={Stylesheets.MarginUpDown}>
        <h2><span>B</span>rowse <span>G</span>ames</h2>
        <GameBox />
      </div>
    </div>
  );
}

export default Home;
