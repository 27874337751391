import React from "react";
import Logo from "../Assets/Logo.png";
import { Link } from "react-router-dom";

const Stylesheets = {
  Navbar_Logo: {
    width: "150px",
  },
};

function Navbar() {
  return (
    <div>
      <nav class="navbar navbar-expand-lg Bg-Nav-Color">
        <div class="container-fluid">
          <Link class="navbar-brand" to="/">
            <img
              src={Logo}
              style={Stylesheets.Navbar_Logo}
              alt="Website Logo"
            />
          </Link>
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse justifyEnd" id="navbarSupportedContent">
            <ul class="navbar-nav mb-2 mb-lg-0"> 
              <li class="nav-item">
                <Link
                  class="nav-link Nav_Color active"
                  aria-current="page"
                  to="/"
                >
                  Home
                </Link>
              </li>

              <li class="nav-item">
                <Link
                  class="nav-link Nav_Color"
                  aria-current="page"
                  to="/Play"
                >
                  Play
                </Link>
              </li>

              <li class="nav-item">
                <Link
                  class="nav-link Nav_Color"
                  aria-current="page"
                  to="/Blog"
                >
                  Blog
                </Link>
              </li>
              <li class="nav-item dropdown">
                <Link class="nav-link dropdown-toggle Nav_Color" to="/" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Dropdown
                </Link>
                <ul style={{ background: "#000000bf", border: "2px solid #f50049" }} class="dropdown-menu">
                  <li><Link class="dropdown-item Nav_Color Nav_Active" to="/Action">Action</Link></li>
                  <li><Link class="dropdown-item Nav_Color Nav_Active" to="/Earn">Earn</Link></li>
                  <li><Link class="dropdown-item Nav_Color Nav_Active" to="/Stylist">Stylish</Link></li>
                  <li><Link class="dropdown-item Nav_Color Nav_Active" to="/Gun">Guns</Link></li>
                  <li><Link class="dropdown-item Nav_Color Nav_Active" to="/Spin">Spin</Link></li>
                  <li><Link class="dropdown-item Nav_Color Nav_Active" to="/Ball">Ball</Link></li>
                  <li><Link class="dropdown-item Nav_Color Nav_Active" to="/Colors">Colors</Link></li>
                </ul>
              </li>
            </ul>
            {/* <Sarchbar /> */}

          </div>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
