import React from "react";
import { Link } from "react-router-dom";

function GameBox() {
  return (
    <div>
      <div className="CG-Main_Box">
        <div className="Games_Box">
          <div className="Image_Box">
            <Link to="/RiverCrossing" className="Image_Box_Link">
              <img src="https://thezgame.com/Assets/G28.png" alt="Games" />
            </Link>
          </div>

          <div className="Image_Box">
            <Link to="/CollectAnimals" className="Image_Box_Link">
              <img src="https://thezgame.com/Assets/G30.png" alt="Games" />
            </Link>
          </div>

          <div className="Image_Box">
            <Link to="/DogeBlocks" className="Image_Box_Link">
              <img src="https://thezgame.com/Assets/G37.png" alt="Games" />
            </Link>
          </div>

          <div className="Image_Box">
            <Link to="/ClickerCuteAnimals" className="Image_Box_Link">
              <img src="https://thezgame.com/Assets/G41.png" alt="Games" />
            </Link>
          </div>

          <div className="Image_Box">
            <Link to="/FindMyDog" className="Image_Box_Link">
              <img src="https://thezgame.com/Assets/G57.png" alt="Games" />
            </Link>
          </div>

          <div className="Image_Box">
            <Link to="/SportCrush" className="Image_Box_Link">
              <img src="https://thezgame.com/Assets/G58.png" alt="Games" />
            </Link>
          </div>

          <div className="Image_Box">
            <Link to="/DogPuzzle" className="Image_Box_Link">
              <img src="https://thezgame.com/Assets/G74.png" alt="Games" />
            </Link>
          </div>

          <div className="Image_Box">
            <Link to="/WordQuest" className="Image_Box_Link">
              <img src="https://thezgame.com/Assets/G75.png" alt="Games" />
            </Link>
          </div>

          <div className="Image_Box">
            <Link to="/RocketDefender" className="Image_Box_Link">
              <img src="https://thezgame.com/Assets/G76.png" alt="Games" />
            </Link>
          </div>

          <div className="Image_Box">
            <Link to="/CircuitGates" className="Image_Box_Link">
              <img src="https://thezgame.com/Assets/G82.png" alt="Games" />
            </Link>
          </div>

          <div className="Image_Box">
            <Link to="/ZombieNightmare" className="Image_Box_Link">
              <img src="https://thezgame.com/Assets/G86.png" alt="Games" />
            </Link>
          </div>

          <div className="Image_Box">
            <Link to="/SpaceJet" className="Image_Box_Link">
              <img src="https://thezgame.com/Assets/G89.png" alt="Games" />
            </Link>
          </div>

          <div className="Image_Box">
            <Link to="/SnowlandAdventure" className="Image_Box_Link">
              <img src="https://thezgame.com/Assets/G96.png" alt="Games" />
            </Link>
          </div>

          <div className="Image_Box">
            <Link to="/MonsterEscape" className="Image_Box_Link">
              <img src="https://thezgame.com/Assets/G111.png" alt="Games" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GameBox;
