import React from "react";

function PrivecyPolicy() {
  return (
    <div>
      <div class="wrapper">
        <h2>Privacy Policy</h2>

        <p>
          This Privacy Policy describes how gamestach ("we", "us", or "our")
          collects, uses, and discloses information when you use our online game
          gamestach (the "Game").
        </p>

        <h2>Information We Collect</h2>

        <p>
          Personal Information: We may collect personal information such as your
          name, email address, and other contact details when you register an
          account or communicate with us through the Game. Game Data: We may
          collect data related to your gameplay, such as game progress,
          achievements, scores, and interactions with other players. Device
          Information: We may collect information about the device you use to
          access the Game, including device model, operating system, unique
          device identifiers, and IP address. How We Use Your Information
        </p>

        <p>
          Provide and Improve the Game: We use the information we collect to
          provide, maintain, and improve the Game, including developing new
          features, optimizing performance, and troubleshooting issues.
          Communicate with You: We may use your contact information to send you
          important updates, announcements, and notifications related to the
          Game. Personalization: We may use your information to personalize your
          gaming experience, such as recommending relevant content or in-game
          offers. Security: We use your information to detect, prevent, and
          address fraud, security breaches, and other illegal activities. Data
          Sharing and Disclosure
        </p>

        <p>
          Service Providers: We may share your information with third-party
          service providers who assist us in operating the Game, such as hosting
          providers, analytics platforms, and customer support services. Legal
          Compliance: We may disclose your information to comply with applicable
          laws, regulations, or legal processes, or to protect the rights,
          property, or safety of [Your Game Name], its users, or others. Data
          Retention
        </p>

        <p>
          We will retain your personal information for as long as necessary to
          fulfill the purposes outlined in this Privacy Policy, unless a longer
          retention period is required or permitted by law.
        </p>

        <h2>Your Choices and Rights</h2>

        <p>
          You may have certain rights and choices regarding your personal
          information, including the right to access, correct, or delete your
          information. Please contact us using the information provided below to
          exercise your rights or if you have any questions or concerns about
          our privacy practices.
        </p>

        <h2>Contact Us</h2>

        <p>
          If you have any questions, comments, or concerns about this Privacy
          Policy or our privacy practices, please contact us at
          support@gamestach.com.
        </p>

        <p>
          We may update this Privacy Policy from time to time to reflect changes
          in our privacy practices or legal requirements. We will notify you of
          any material changes by posting the updated Privacy Policy on this
          page.
        </p>
      </div>
    </div>
  );
}

export default PrivecyPolicy;
