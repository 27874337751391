import React from "react";

function tearmandcondition() {
  return (
    <div>
      <div class="wrapper">
        <h2>Terms and Conditions</h2>

        <p>
          These Terms and Conditions ("Terms") govern your use of gamestach ("the
          Game"), including any related websites, applications, and services. By
          accessing or using the Game, you agree to comply with these Terms. If
          you do not agree with these Terms, you may not use the Game.
        </p>

        <h2>1. Account Registration</h2>

        <p>
          1.1. To access certain features of the Game, you may be required to
          register for an account. You agree to provide accurate, current, and
          complete information during the registration process and to keep your
          account information updated.
        </p>

        <p>
          1.2. You are responsible for maintaining the confidentiality of your
          account credentials and for all activities that occur under your
          account. You agree to notify us immediately of any unauthorized use of
          your account or any other security breach.
        </p>

        <h2>2. Use of the Game</h2>

        <p>
          2.1. You may use the Game for lawful purposes only and in accordance
          with these Terms. You agree not to use the Game for any unlawful or
          prohibited purpose or in any manner that could damage, disable,
          overburden, or impair the Game.
        </p>

        <p>
          2.2. You agree not to interfere with the operation of the Game,
          including by transmitting viruses, malware, or any other harmful code,
          or by engaging in any other conduct that disrupts or interferes with
          the Game's functionality.
        </p>

        <h2>3. Intellectual Property</h2>

        <p>
          3.1. The Game and its content, including but not limited to text,
          graphics, logos, images, audio clips, and software, are owned or
          licensed by us and are protected by copyright, trademark, and other
          intellectual property laws.
        </p>

        <p>
          3.2. You are granted a limited, non-exclusive, revocable license to
          access and use the Game for personal, non-commercial purposes only.
          You may not copy, modify, distribute, transmit, display, perform, or
          create derivative works based on the Game or its content without our
          prior written consent.
        </p>

        <h2>4. User Content</h2>

        <p>
          4.1. The Game may allow you to submit or upload content, including but
          not limited to text, images, and videos ("User Content"). By
          submitting User Content, you grant us a non-exclusive, royalty-free,
          perpetual, worldwide, and irrevocable license to use, reproduce,
          modify, adapt, publish, translate, distribute, and display the User
          Content in connection with the Game.
        </p>

        <p>
          4.2. You represent and warrant that you have the necessary rights to
          grant the above license for any User Content you submit and that your
          User Content does not violate any third-party rights or applicable
          laws.
        </p>

        <h2>5. Limitation of Liability</h2>

        <p>
          5.1. To the fullest extent permitted by law, we shall not be liable
          for any direct, indirect, incidental, special, consequential, or
          punitive damages arising out of or in connection with your use of the
          Game or these Terms, even if we have been advised of the possibility
          of such damages.
        </p>

        <p>
          5.2. Our total liability to you for any claim arising out of or in
          connection with these Terms or your use of the Game shall not exceed
          the amount paid by you, if any, to us for access to the Game.
        </p>

        <h2>6. Termination</h2>

        <p>
          6.1. We reserve the right to suspend or terminate your access to the
          Game at any time and for any reason, without prior notice or
          liability. Upon termination, your right to use the Game will
          immediately cease, and you must cease all use of the Game and destroy
          any copies of the Game in your possession.
        </p>

        <h2>7. Governing Law</h2>

        <p>
          7.1. These Terms and any dispute or claim arising out of or in
          connection with them shall be governed by and construed in accordance
          with the laws of [Your Jurisdiction], without regard to its conflict
          of law provisions.
        </p>

        <h2>8. Changes to these Terms</h2>

        <p>
          8.1. We reserve the right to modify or update these Terms at any time
          without prior notice. Any changes to these Terms will be effective
          immediately upon posting the revised Terms on this page. Your
          continued use of the Game after the posting of the revised Terms
          constitutes your acceptance of the changes.
        </p>

        <h2>9. Contact Us</h2>

        <p>
          9.1. If you have any questions, comments, or concerns about these
          Terms or the Game, please contact us at support@gamestach.com.
        </p>
      </div>
    </div>
  );
}

export default tearmandcondition;
