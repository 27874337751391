import React from "react";
import Logo from "../Assets/Logo.png";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className="MainFooterPart">
      <section style={{height: "auto !important"}}>
        <footer style={{height: "auto !important"}}>
          <div class="mt-30 row primary">
            <div class="column about">

              <h3 class="Footer_H3 Extra_Footer_H3">
              <Link to={"/"}>
              <img style={{width: "230px", marginTop: "-8px"}} alt="Logo" src={Logo} />
              </Link>
              </h3>

              <p>
                Welcome to GameStack, where virtual worlds come to life! We are a passionate team of gamers,
              </p>

              <div class="social">
                <i class="fa-brands fa-facebook-square"></i>
                <i class="fa-brands fa-instagram-square"></i>
                <i class="fa-brands fa-twitter-square"></i>
                <i class="fa-brands fa-youtube-square"></i>
                <i class="fa-brands fa-whatsapp-square"></i>
              </div>
            </div>

            <div class="column links">
              <h3 class="Footer_H3">Populer Game</h3>
              <ul class="Footer_Ul">
                <li>
                  <Link to="/GuessWho">GuessWho</Link>
                </li>
                <li>
                  <Link to="/BladeBallon">BladeBallon</Link>
                </li>
                <li>
                  <Link to="/Dual">Dual</Link>
                </li>
                <li>
                  <Link to="/SlotBallFes">SlotBall</Link>
                </li>
              </ul>
            </div>


            <div class="column links">
              <h3 class="Footer_H3">Classic Games</h3>
              <ul class="Footer_Ul">
                <li>
                  <Link to="/HappySnake2">Snake2</Link>
                </li>
                <li>
                  <Link to="/BallCar">BallCar</Link>
                </li>
                <li>
                  <Link to="/PushPuzzleFootball">PushPuzzle</Link>
                </li>
                <li>
                  <Link to="/FindGold">FindGold</Link>
                </li>
              </ul>
            </div>
            
            <div class="column links New_Link">
              <h3 class="Footer_H3">Classic Games</h3>
              <ul class="Footer_Ul">
                <li>
                  <Link to="/SuperBitcoin">SuperBitcoin</Link>
                </li>
                <li>
                  <Link to="/SlotMachineFruits">SlotMachine</Link>
                </li>
                <li>
                  <Link to="/GapGap">GapGap</Link>
                </li>
                <li>
                  <Link to="/Around">Around</Link>
                </li>
              </ul>
            </div>

          </div>

          <div class="row copyright">
            <div class="footer-menu">

              <Link to="/">Home</Link>
              <Link refresh="true" to="/PrivecyPolicy">Privacy Policy</Link>
              <Link to="/tearmandcondition">Terms &amp; Condition</Link>
              <Link to="/">About Us</Link>
            </div>
            <p>Copyright © 2024 GameStach Developer</p>
          </div>
        </footer>
      </section>
    </div>
  );
}

export default Footer;
