import React from "react";
import Description from "../../Components/Description";
import GameBox from '../../Components/GameBox'

function G51() {
  return (
    <div>
      <div className="GB-Main_Game">
        <div className="Main_Fram_Box">
          <iframe
            title="Play And Fun"
            src="https://jscodergame.netlify.app/g61.html"
            frameborder="0"
          ></iframe>
        </div>
      </div>

      <div style={{marginTop: "30px"}}>
        <Description />
      </div>

      <div style={{marginTop: "20px"}}>
        <GameBox />
      </div>
    </div>
  );
}

export default G51;
