import React from "react";
import { Link } from "react-router-dom";

function GameBox() {
  return (
    <div>
      <div className="CG-Main_Box">
        <div className="Games_Box">
          <div className="Image_Box">
            <Link to="/TopJump" className="Image_Box_Link">
              <img src="https://thezgame.com/Assets/G65.png" alt="Games" />
            </Link>
          </div>

          <div className="Image_Box">
            <Link to="/DesertBuilding" className="Image_Box_Link">
              <img src="https://thezgame.com/Assets/G66.png" alt="Games" />
            </Link>
          </div>

          <div className="Image_Box">
            <Link to="/FourColors" className="Image_Box_Link">
              <img src="https://thezgame.com/Assets/G78.png" alt="Games" />
            </Link>
          </div>

          <div className="Image_Box">
            <Link to="/GuessTheFlag" className="Image_Box_Link">
              <img src="https://thezgame.com/Assets/G79.png" alt="Games" />
            </Link>
          </div>

          <div className="Image_Box">
            <Link to="/Pin" className="Image_Box_Link">
              <img src="https://thezgame.com/Assets/G85.png" alt="Games" />
            </Link>
          </div>

          <div className="Image_Box">
            <Link to="/SlotKites" className="Image_Box_Link">
              <img src="https://thezgame.com/Assets/G108.png" alt="Games" />
            </Link>
          </div>

          <div className="Image_Box">
            <Link to="/FindMiniMe" className="Image_Box_Link">
              <img src="https://thezgame.com/Assets/G109.png" alt="Games" />
            </Link>
          </div>

          <div className="Image_Box">
            <Link to="/RogueRaider" className="Image_Box_Link">
              <img src="https://thezgame.com/Assets/G110.png" alt="Games" />
            </Link>
          </div>

          <div className="Image_Box">
            <Link to="/Plux" className="Image_Box_Link">
              <img src="https://thezgame.com/Assets/G117.png" alt="Games" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GameBox;
