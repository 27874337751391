import React from 'react'

function G1() {
  return (
    <div>
      <div className="GG-GameIfram">
        <iframe
          title="Play And Fun"
          src="https://jscodergame.netlify.app/g81.html"
          frameborder="0"
        ></iframe>
      </div>
    </div>
  )
}

export default G1
