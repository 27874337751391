import React from "react";
import { Link } from "react-router-dom";

function GameBox() {
  return (
    <div>
      <div className="CG-Main_Box">
        <div className="Games_Box">
          <div className="Image_Box">
            <Link to="/ApplesCut" className="Image_Box_Link">
              <img src="https://thezgame.com/Assets/G59.png" alt="Games" />
            </Link>
          </div>

          <div className="Image_Box">
            <Link to="/Numberle" className="Image_Box_Link">
              <img src="https://thezgame.com/Assets/G60.png" alt="Games" />
            </Link>
          </div>

          <div className="Image_Box">
            <Link to="/BrainGame" className="Image_Box_Link">
              <img src="https://thezgame.com/Assets/G61.png" alt="Games" />
            </Link>
          </div>

          <div className="Image_Box">
            <Link to="/WatermelonFruit" className="Image_Box_Link">
              <img src="https://thezgame.com/Assets/G104.png" alt="Games" />
            </Link>
          </div>

          <div className="Image_Box">
            <Link to="/Plinko" className="Image_Box_Link">
              <img src="https://thezgame.com/Assets/G105.png" alt="Games" />
            </Link>
          </div>

          <div className="Image_Box">
            <Link to="/PizzaGuyPuzzle" className="Image_Box_Link">
              <img src="https://thezgame.com/Assets/G106.png" alt="Games" />
            </Link>
          </div>

          <div className="Image_Box">
            <Link to="/StackCheese" className="Image_Box_Link">
              <img src="https://thezgame.com/Assets/G113.png" alt="Games" />
            </Link>
          </div>

          <div className="Image_Box">
            <Link to="/PastryChefBubble" className="Image_Box_Link">
              <img src="https://thezgame.com/Assets/G114.png" alt="Games" />
            </Link>
          </div>

          <div className="Image_Box">
            <Link to="/FormulaRush" className="Image_Box_Link">
              <img src="https://thezgame.com/Assets/G116.png" alt="Games" />
            </Link>
          </div>

          <div className="Image_Box">
            <Link to="/FruitMergeReoader" className="Image_Box_Link">
              <img src="https://thezgame.com/Assets/G118.png" alt="Games" />
            </Link>
          </div>

          <div className="Image_Box Secgame_Box">
            <Link to="/SeekAndFind" className="Image_Box_Link">
              <img src="https://thezgame.com/Assets/G120.png" alt="Games" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GameBox;
