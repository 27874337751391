import React from "react";
import { Routes } from "react-router-dom";
import { Route } from "react-router-dom";

import Home from "./Views/Home";
import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";

import G1 from "./Views/GamesPages/G1";
import G2 from "./Views/GamesPages/G2";
import G3 from "./Views/GamesPages/G3";
import G4 from "./Views/GamesPages/G4";
import G5 from "./Views/GamesPages/G5";
import G6 from "./Views/GamesPages/G6";
import G7 from "./Views/GamesPages/G7";
import G8 from "./Views/GamesPages/G8";
import G9 from "./Views/GamesPages/G9";
import G10 from "./Views/GamesPages/G10";

import G11 from "./Views/GamesPages/G11";
import G12 from "./Views/GamesPages/G12";
import G13 from "./Views/GamesPages/G13";
import G14 from "./Views/GamesPages/G14";
import G15 from "./Views/GamesPages/G15";
import G16 from "./Views/GamesPages/G16";
import G17 from "./Views/GamesPages/G17";
import G18 from "./Views/GamesPages/G18";
import G19 from "./Views/GamesPages/G19";
import G20 from "./Views/GamesPages/G20";

import G21 from "./Views/GamesPages/G21";
import G22 from "./Views/GamesPages/G22";
import G23 from "./Views/GamesPages/G23";
import G24 from "./Views/GamesPages/G24";
import G25 from "./Views/GamesPages/G25";
import G26 from "./Views/GamesPages/G26";
import G27 from "./Views/GamesPages/G27";
import G28 from "./Views/GamesPages/G28";
import G29 from "./Views/GamesPages/G29";
import G30 from "./Views/GamesPages/G30";

import G31 from "./Views/GamesPages/G31";
import G32 from "./Views/GamesPages/G32";
import G33 from "./Views/GamesPages/G33";
import G34 from "./Views/GamesPages/G34";
import G35 from "./Views/GamesPages/G35";
import G36 from "./Views/GamesPages/G36";
import G37 from "./Views/GamesPages/G37";
import G38 from "./Views/GamesPages/G38";
import G39 from "./Views/GamesPages/G39";
import G40 from "./Views/GamesPages/G40";

import G41 from "./Views/GamesPages/G41";
import G42 from "./Views/GamesPages/G42";
import G43 from "./Views/GamesPages/G43";
import G44 from "./Views/GamesPages/G44";
import G45 from "./Views/GamesPages/G45";
import G46 from "./Views/GamesPages/G46";
import G47 from "./Views/GamesPages/G47";
import G48 from "./Views/GamesPages/G48";
import G49 from "./Views/GamesPages/G49";
import G50 from "./Views/GamesPages/G50";

import G51 from "./Views/GamesPages/G51";
import G52 from "./Views/GamesPages/G52";
import G53 from "./Views/GamesPages/G53";
import G54 from "./Views/GamesPages/G54";
import G55 from "./Views/GamesPages/G55";
import G56 from "./Views/GamesPages/G56";
import G57 from "./Views/GamesPages/G57";
import G58 from "./Views/GamesPages/G58";
import G59 from "./Views/GamesPages/G59";
import G60 from "./Views/GamesPages/G60";

import G61 from "./Views/GamesPages/G61";
import G62 from "./Views/GamesPages/G62";
import G63 from "./Views/GamesPages/G63";
import G64 from "./Views/GamesPages/G64";
import G65 from "./Views/GamesPages/G65";
import G66 from "./Views/GamesPages/G66";
import G67 from "./Views/GamesPages/G67";
import G68 from "./Views/GamesPages/G68";
import G69 from "./Views/GamesPages/G69";
import G70 from "./Views/GamesPages/G70";

import G71 from "./Views/GamesPages/G71";
import G72 from "./Views/GamesPages/G72";
import G73 from "./Views/GamesPages/G73";
import G74 from "./Views/GamesPages/G74";
import G75 from "./Views/GamesPages/G75";
import G76 from "./Views/GamesPages/G76";
import G77 from "./Views/GamesPages/G77";
import G78 from "./Views/GamesPages/G78";
import G79 from "./Views/GamesPages/G79";
import G80 from "./Views/GamesPages/G80";

import G81 from "./Views/GamesPages/G81";
import G82 from "./Views/GamesPages/G82";
import G83 from "./Views/GamesPages/G83";
import G84 from "./Views/GamesPages/G84";
import G85 from "./Views/GamesPages/G85";
import G86 from "./Views/GamesPages/G86";
import G87 from "./Views/GamesPages/G87";
import G88 from "./Views/GamesPages/G88";
import G89 from "./Views/GamesPages/G89";
import G90 from "./Views/GamesPages/G90";

import G91 from "./Views/GamesPages/G91";
import G92 from "./Views/GamesPages/G92";
import G93 from "./Views/GamesPages/G93";
import G94 from "./Views/GamesPages/G94";
import G95 from "./Views/GamesPages/G95";
import G96 from "./Views/GamesPages/G96";
import G97 from "./Views/GamesPages/G97";
import G98 from "./Views/GamesPages/G98";
import G99 from "./Views/GamesPages/G99";
import G100 from "./Views/GamesPages/G100";

import G101 from "./Views/GamesPages/G101";
import G102 from "./Views/GamesPages/G102";
import G103 from "./Views/GamesPages/G103";
import G104 from "./Views/GamesPages/G104";
import G105 from "./Views/GamesPages/G105";
import G106 from "./Views/GamesPages/G106";
import G107 from "./Views/GamesPages/G107";
import G108 from "./Views/GamesPages/G108";
import G109 from "./Views/GamesPages/G109";
import G110 from "./Views/GamesPages/G110";

import Game1 from "./Views/Games/G1";
import Game2 from "./Views/Games/G2";
import Game3 from "./Views/Games/G3";
import Game4 from "./Views/Games/G4";
import Game5 from "./Views/Games/G5";
import Game6 from "./Views/Games/G6";
import Game7 from "./Views/Games/G7";
import Game8 from "./Views/Games/G8";
import Game9 from "./Views/Games/G9";
import Game10 from "./Views/Games/G10";

import Game11 from "./Views/Games/G11";
import Game12 from "./Views/Games/G12";
import Game13 from "./Views/Games/G13";
import Game14 from "./Views/Games/G14";
import Game15 from "./Views/Games/G15";
import Game16 from "./Views/Games/G16";
import Game17 from "./Views/Games/G17";
import Game18 from "./Views/Games/G18";
import Game19 from "./Views/Games/G19";
import Game20 from "./Views/Games/G20";

import Game21 from "./Views/Games/G21";
import Game22 from "./Views/Games/G22";
import Game23 from "./Views/Games/G23";
import Game24 from "./Views/Games/G24";
import Game25 from "./Views/Games/G25";
import Game26 from "./Views/Games/G26";
import Game27 from "./Views/Games/G27";
import Game28 from "./Views/Games/G28";
import Game29 from "./Views/Games/G29";
import Game30 from "./Views/Games/G30";

import Game31 from "./Views/Games/G31";
import Game32 from "./Views/Games/G32";
import Game33 from "./Views/Games/G33";
import Game34 from "./Views/Games/G34";
import Game35 from "./Views/Games/G35";
import Game36 from "./Views/Games/G36";
import Game37 from "./Views/Games/G37";
import Game38 from "./Views/Games/G38";
import Game39 from "./Views/Games/G39";
import Game40 from "./Views/Games/G40";

import Game41 from "./Views/Games/G41";
import Game42 from "./Views/Games/G42";
import Game43 from "./Views/Games/G43";
import Game44 from "./Views/Games/G44";
import Game45 from "./Views/Games/G45";
import Game46 from "./Views/Games/G46";
import Game47 from "./Views/Games/G47";
import Game48 from "./Views/Games/G48";
import Game49 from "./Views/Games/G49";
import Game50 from "./Views/Games/G50";

import Game51 from "./Views/Games/G51";
import Game52 from "./Views/Games/G52";
import Game53 from "./Views/Games/G53";
import Game54 from "./Views/Games/G54";
import Game55 from "./Views/Games/G55";
import Game56 from "./Views/Games/G56";
import Game57 from "./Views/Games/G57";
import Game58 from "./Views/Games/G58";
import Game59 from "./Views/Games/G59";
import Game60 from "./Views/Games/G60";

import Game61 from "./Views/Games/G61";
import Game62 from "./Views/Games/G62";
import Game63 from "./Views/Games/G63";
import Game64 from "./Views/Games/G64";
import Game65 from "./Views/Games/G65";
import Game66 from "./Views/Games/G66";
import Game67 from "./Views/Games/G67";
import Game68 from "./Views/Games/G68";
import Game69 from "./Views/Games/G69";
import Game70 from "./Views/Games/G70";

import Game71 from "./Views/Games/G71";
import Game72 from "./Views/Games/G72";
import Game73 from "./Views/Games/G73";
import Game74 from "./Views/Games/G74";
import Game75 from "./Views/Games/G75";
import Game76 from "./Views/Games/G76";
import Game77 from "./Views/Games/G77";
import Game78 from "./Views/Games/G78";
import Game79 from "./Views/Games/G79";
import Game80 from "./Views/Games/G80";

import Game81 from "./Views/Games/G81";
import Game82 from "./Views/Games/G82";
import Game83 from "./Views/Games/G83";
import Game84 from "./Views/Games/G84";
import Game85 from "./Views/Games/G85";
import Game86 from "./Views/Games/G86";
import Game87 from "./Views/Games/G87";
import Game88 from "./Views/Games/G88";
import Game89 from "./Views/Games/G89";
import Game90 from "./Views/Games/G90";

import Game91 from "./Views/Games/G91";
import Game92 from "./Views/Games/G92";
import Game93 from "./Views/Games/G93";
import Game94 from "./Views/Games/G94";
import Game95 from "./Views/Games/G95";
import Game96 from "./Views/Games/G96";
import Game97 from "./Views/Games/G97";
import Game98 from "./Views/Games/G98";
import Game99 from "./Views/Games/G99";
import Game100 from "./Views/Games/G100";

import Game101 from "./Views/Games/G101";
import Game102 from "./Views/Games/G102";
import Game103 from "./Views/Games/G103";
import Game104 from "./Views/Games/G104";
import Game105 from "./Views/Games/G105";
import Game106 from "./Views/Games/G106";
import Game107 from "./Views/Games/G107";
import Game108 from "./Views/Games/G108";
import Game109 from "./Views/Games/G109";
import Game110 from "./Views/Games/G110";


import Action from './Components/Category/Action'
import Ball from './Components/Category/Ball'
import Colors from './Components/Category/Colors'
import Fruit from './Components/Category/Fruit'
import Dog from './Components/Category/Dog'
import Earn from './Components/Category/Earn'
import Gun from './Components/Category/Gun'
import Village from './Components/Category/Village'
import Spin from './Components/Category/Spin'
import Stylist from './Components/Category/Stylist'

import Blog from "./Views/Blog";
import Play from "./Views/Play";

import Tearmandcondition from "./Views/tearmandcondition";
import PrivecyPolicy from "./Views/PrivecyPolicy";

import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function App() {
  const location = useLocation();
  useEffect(() => {
    window.scroll(0, 0);
  }, [location]);
  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Blog" element={<Blog />} />
        <Route path="/Play" element={<Play />} />

        <Route path="/Action" element={<Action />} />
        <Route path="/Earn" element={<Earn />} />
        <Route path="/Stylist" element={<Stylist />} />
        <Route path="/Gun" element={<Gun />} />
        <Route path="/Spin" element={<Spin />} />
        <Route path="/Ball" element={<Ball />} />
        <Route path="/Colors" element={<Colors />} />
        <Route path="/Village" element={<Village />} />
        <Route path="/Fruit" element={<Fruit />} />
        <Route path="/Dog" element={<Dog />} />

        <Route path="/tearmandcondition" element={<Tearmandcondition />} />
        <Route path="/PrivecyPolicy" element={<PrivecyPolicy />} />

        <Route path="/GuessWho" element={<G1 />} />
        <Route path="/BladeBallon" element={<G2 />} />
        <Route path="/Dual" element={<G3 />} />
        <Route path="/DeoNether" element={<G4 />} />
        <Route path="/SlotBallFes" element={<G5 />} />
        <Route path="/HappySnake2" element={<G6 />} />
        <Route path="/BallCar" element={<G7 />} />
        <Route path="/BoyPingPong" element={<G8 />} />
        <Route path="/PushPuzzleFootball" element={<G9 />} />
        <Route path="/FindGold" element={<G10 />} />

        <Route path="/SuperBitcoin" element={<G11 />} />
        <Route path="/SlotMachineFruits" element={<G12 />} />
        <Route path="/GapGap" element={<G13 />} />
        <Route path="/Around" element={<G14 />} />
        <Route path="/RailMaze" element={<G15 />} />
        <Route path="/ColorGrid" element={<G16 />} />
        <Route path="/MergeCandy" element={<G17 />} />
        <Route path="/RiverCrossing" element={<G18 />} />
        <Route path="/CandyDash" element={<G19 />} />
        <Route path="/CollectAnimals" element={<G20 />} />

        <Route path="/SlotCowboys" element={<G21 />} />
        <Route path="/BridgeRunner" element={<G22 />} />
        <Route path="/BoxTower" element={<G23 />} />
        <Route path="/ExtremePlinko" element={<G24 />} />
        <Route path="/IcyInterval" element={<G25 />} />
        <Route path="/CreepyFlappy" element={<G26 />} />
        <Route path="/DogeBlocks" element={<G27 />} />
        <Route path="/MonthsChallenge" element={<G28 />} />
        <Route path="/PlayDominoes" element={<G29 />} />
        <Route path="/SingleChain" element={<G30 />} />

        <Route path="/ClickerCuteAnimals" element={<G31 />} />
        <Route path="/NinjaFruitSlice" element={<G32 />} />
        <Route path="/Basket" element={<G33 />} />
        <Route path="/FinishTheDrawing" element={<G34 />} />
        <Route path="/ShadowForestSlot" element={<G35 />} />
        <Route path="/IconSpinWheels" element={<G36 />} />
        <Route path="/SlotAstro" element={<G37 />} />
        <Route path="/Moles" element={<G38 />} />
        <Route path="/MayaRuins" element={<G39 />} />
        <Route path="/AirHockey" element={<G40 />} />

        <Route path="/FruitsFit" element={<G41 />} />
        <Route path="/TreasureJungle" element={<G42 />} />
        <Route path="/SortBucket" element={<G43 />} />
        <Route path="/DotBlast" element={<G44 />} />
        <Route path="/NoelNavigate" element={<G45 />} />
        <Route path="/Colors" element={<G46 />} />
        <Route path="/FindMyDog" element={<G47 />} />
        <Route path="/SportCrush" element={<G48 />} />
        <Route path="/ApplesCut" element={<G49 />} />
        <Route path="/Numberle" element={<G50 />} />

        <Route path="/BrainGame" element={<G51 />} />
        <Route path="/Spaceship" element={<G52 />} />
        <Route path="/Stoneman" element={<G53 />} />
        <Route path="/Tricolor" element={<G54 />} />
        <Route path="/TopJump" element={<G55 />} />
        <Route path="/DesertBuilding" element={<G56 />} />
        <Route path="/CrazyCannons" element={<G57 />} />
        <Route path="/ChooseYourOdds" element={<G58 />} />
        <Route path="/TheMagicOfScience" element={<G59 />} />
        <Route path="/Fishes" element={<G60 />} />

        <Route path="/BabySupermarts" element={<G61 />} />
        <Route path="/ArcherBattle" element={<G62 />} />
        <Route path="/Arrows" element={<G63 />} />
        <Route path="/DogPuzzle" element={<G64 />} />
        <Route path="/WordQuest" element={<G65 />} />
        <Route path="/RocketDefender" element={<G66 />} />
        <Route path="/SpeedNeon" element={<G67 />} />
        <Route path="/FourColors" element={<G68 />} />
        <Route path="/GuessTheFlag" element={<G69 />} />
        <Route path="/TiltPainting" element={<G70 />} />

        <Route path="/WordConnect" element={<G71 />} />
        <Route path="/CircuitGates" element={<G72 />} />
        <Route path="/HatHands" element={<G73 />} />
        <Route path="/Chidimaar" element={<G74 />} />
        <Route path="/Pin" element={<G75 />} />
        <Route path="/ZombieNightmare" element={<G76 />} />
        <Route path="/SkaterBoy" element={<G77 />} />
        <Route path="/RollBall" element={<G78 />} />
        <Route path="/SpaceJet" element={<G79 />} />
        <Route path="/BasketMode" element={<G80 />} />

        <Route path="/NinjaTime" element={<G81 />} />
        <Route path="/Collide2" element={<G82 />} />
        <Route path="/EggFarm" element={<G83 />} />
        <Route path="/TowerLevels" element={<G84 />} />
        <Route path="/DualContrl" element={<G85 />} />
        <Route path="/SnowlandAdventure" element={<G86 />} />
        <Route path="/WatermelonDrop" element={<G87 />} />
        <Route path="/RiverCrossing" element={<G88 />} />
        <Route path="/PatternMaster" element={<G89 />} />
        <Route path="/AGoodTeacher" element={<G90 />} />

        <Route path="/WobblyBoxing" element={<G91 />} />
        <Route path="/MathGeniusGame" element={<G92 />} />
        <Route path="/NumbersCloud" element={<G93 />} />
        <Route path="/WatermelonFruit" element={<G94 />} />
        <Route path="/Plinko" element={<G95 />} />
        <Route path="/PizzaGuyPuzzle" element={<G96 />} />
        <Route path="/ArcoBall" element={<G97 />} />
        <Route path="/SlotKites" element={<G98 />} />
        <Route path="/FindMiniMe" element={<G99 />} />
        <Route path="/RogueRaider" element={<G100 />} />

        <Route path="/MonsterEscape" element={<G101 />} />
        <Route path="/CarRush" element={<G102 />} />
        <Route path="/StackCheese" element={<G103 />} />
        <Route path="/PastryChefBubble" element={<G104 />} />
        <Route path="/AngryShooter" element={<G105 />} />
        <Route path="/FormulaRush" element={<G106 />} />
        <Route path="/Plux" element={<G107 />} />
        <Route path="/FruitMergeReoader" element={<G108 />} />
        <Route path="/CircuitGates" element={<G109 />} />
        <Route path="/SeekAndFind" element={<G110 />} />

        <Route path="/G1" element={<Game1 />} />
        <Route path="/G2" element={<Game2 />} />
        <Route path="/G3" element={<Game3 />} />
        <Route path="/G4" element={<Game4 />} />
        <Route path="/G5" element={<Game5 />} />
        <Route path="/G6" element={<Game6 />} />
        <Route path="/G7" element={<Game7 />} />
        <Route path="/G8" element={<Game8 />} />
        <Route path="/G9" element={<Game9 />} />
        <Route path="/G10" element={<Game10 />} />

        <Route path="/G11" element={<Game11 />} />
        <Route path="/G12" element={<Game12 />} />
        <Route path="/G13" element={<Game13 />} />
        <Route path="/G14" element={<Game14 />} />
        <Route path="/G15" element={<Game15 />} />
        <Route path="/G16" element={<Game16 />} />
        <Route path="/G17" element={<Game17 />} />
        <Route path="/G18" element={<Game18 />} />
        <Route path="/G19" element={<Game19 />} />
        <Route path="/G20" element={<Game20 />} />

        <Route path="/G21" element={<Game21 />} />
        <Route path="/G22" element={<Game22 />} />
        <Route path="/G23" element={<Game23 />} />
        <Route path="/G24" element={<Game24 />} />
        <Route path="/G25" element={<Game25 />} />
        <Route path="/G26" element={<Game26 />} />
        <Route path="/G27" element={<Game27 />} />
        <Route path="/G28" element={<Game28 />} />
        <Route path="/G29" element={<Game29 />} />
        <Route path="/G30" element={<Game30 />} />

        <Route path="/G31" element={<Game31 />} />
        <Route path="/G32" element={<Game32 />} />
        <Route path="/G33" element={<Game33 />} />
        <Route path="/G34" element={<Game34 />} />
        <Route path="/G35" element={<Game35 />} />
        <Route path="/G36" element={<Game36 />} />
        <Route path="/G37" element={<Game37 />} />
        <Route path="/G38" element={<Game38 />} />
        <Route path="/G39" element={<Game39 />} />
        <Route path="/G40" element={<Game40 />} />

        <Route path="/G41" element={<Game41 />} />
        <Route path="/G42" element={<Game42 />} />
        <Route path="/G43" element={<Game43 />} />
        <Route path="/G44" element={<Game44 />} />
        <Route path="/G45" element={<Game45 />} />
        <Route path="/G46" element={<Game46 />} />
        <Route path="/G47" element={<Game47 />} />
        <Route path="/G48" element={<Game48 />} />
        <Route path="/G49" element={<Game49 />} />
        <Route path="/G50" element={<Game50 />} />

        <Route path="/G51" element={<Game51 />} />
        <Route path="/G52" element={<Game52 />} />
        <Route path="/G53" element={<Game53 />} />
        <Route path="/G54" element={<Game54 />} />
        <Route path="/G55" element={<Game55 />} />
        <Route path="/G56" element={<Game56 />} />
        <Route path="/G57" element={<Game57 />} />
        <Route path="/G58" element={<Game58 />} />
        <Route path="/G59" element={<Game59 />} />
        <Route path="/G60" element={<Game60 />} />

        <Route path="/G61" element={<Game61 />} />
        <Route path="/G62" element={<Game62 />} />
        <Route path="/G63" element={<Game63 />} />
        <Route path="/G64" element={<Game64 />} />
        <Route path="/G65" element={<Game65 />} />
        <Route path="/G66" element={<Game66 />} />
        <Route path="/G67" element={<Game67 />} />
        <Route path="/G68" element={<Game68 />} />
        <Route path="/G69" element={<Game69 />} />
        <Route path="/G70" element={<Game70 />} />

        <Route path="/G71" element={<Game71 />} />
        <Route path="/G72" element={<Game72 />} />
        <Route path="/G73" element={<Game73 />} />
        <Route path="/G74" element={<Game74 />} />
        <Route path="/G75" element={<Game75 />} />
        <Route path="/G76" element={<Game76 />} />
        <Route path="/G77" element={<Game77 />} />
        <Route path="/G78" element={<Game78 />} />
        <Route path="/G79" element={<Game79 />} />
        <Route path="/G80" element={<Game80 />} />

        <Route path="/G81" element={<Game81 />} />
        <Route path="/G82" element={<Game82 />} />
        <Route path="/G83" element={<Game83 />} />
        <Route path="/G84" element={<Game84 />} />
        <Route path="/G85" element={<Game85 />} />
        <Route path="/G86" element={<Game86 />} />
        <Route path="/G87" element={<Game87 />} />
        <Route path="/G88" element={<Game88 />} />
        <Route path="/G89" element={<Game89 />} />
        <Route path="/G90" element={<Game90 />} />

        <Route path="/G91" element={<Game91 />} />
        <Route path="/G92" element={<Game92 />} />
        <Route path="/G93" element={<Game93 />} />
        <Route path="/G94" element={<Game94 />} />
        <Route path="/G95" element={<Game95 />} />
        <Route path="/G96" element={<Game96 />} />
        <Route path="/G97" element={<Game97 />} />
        <Route path="/G98" element={<Game98 />} />
        <Route path="/G99" element={<Game99 />} />
        <Route path="/G100" element={<Game100 />} />

        <Route path="/G101" element={<Game101 />} />
        <Route path="/G102" element={<Game102 />} />
        <Route path="/G103" element={<Game103 />} />
        <Route path="/G104" element={<Game104 />} />
        <Route path="/G105" element={<Game105 />} />
        <Route path="/G106" element={<Game106 />} />
        <Route path="/G107" element={<Game107 />} />
        <Route path="/G108" element={<Game108 />} />
        <Route path="/G109" element={<Game109 />} />
        <Route path="/G110" element={<Game110 />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
