import React from 'react'
import GameBox from '../Components/GameBox'

function Play() {
  return (
    <div>
      <GameBox />
    </div>
  )
}

export default Play