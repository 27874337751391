import React from "react";
import Description from "../../Components/Description";
import GameBox from '../../Components/GameBox'
import { Link } from "react-router-dom";

function G1() {
  return (
    <div>
      <div className="GB-Main_Game">
        <div className="Main_Fram_Box G39">
          <div>
            <div>
              <div className="GP-Icon">
                <img src="https://thezgame.com/Assets/G107.png" alt="" />
              </div>

              <div className="GP-Button">
                <Link to="/G97">Play</Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style={{marginTop: "30px"}}>
        <Description />
      </div>

      <div style={{marginTop: "20px"}}>
        <GameBox />
      </div>
    </div>
  );
}

export default G1;
